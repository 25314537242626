
<ion-toolbar color="secondary">
  <ion-buttons slot="end">
    <ion-button (click)="closeModal()">Fermer</ion-button>
  </ion-buttons>
  <ion-title>    <h2 style="margin-left: 10px;">Déclenchement des interventions</h2></ion-title>
</ion-toolbar>
<ion-content>

  <ion-button *ngIf="sites"  expand="full" (click)="openPopOver('Liste des types d\'intervention',[{nom_inter:'Installation',id_inter:'Installation'},{nom_inter:'Déplacement',id_inter:'deplacement'}],'nom_inter','id_inter','Filtrer les types d\'intervention',false,'type_inter')">Selectionnez un type d'intervention</ion-button>
  <ion-item *ngIf="type_inter">
    <ion-label>{{type_inter?.nom_inter}}</ion-label>
  </ion-item>


  <ion-button [disabled]="!type_inter" *ngIf="sites" expand="full" (click)="openPopOver('Liste des sites',sites,'nom_sit','id_sit','Filtrer les sites',false,'site')">Selectionnez un site</ion-button>
  <ion-item *ngIf="site">
    <ion-label>{{this.site.nom_sit}}</ion-label>
  </ion-item>
  <ion-button *ngIf="sites" [disabled]="!site || !sujets || sujets.length == 0" expand="full" (click)="openPopOver('Liste des sujets',sujets,'nom_suj','id_suj','Filtrer les sujets',true,'sujetsFC','statut')">Selectionnez des sujets</ion-button>

  <ion-item *ngIf="sujets && sujets.length == 0">
    <ion-label >
      <h2><strong>Aucun sujet à équiper pour ce site</strong></h2>
    </ion-label>
  </ion-item>
  <ion-list *ngIf="sujetsFC && sujetsFC.length > 0">
      <ion-item *ngFor="let sujet of sujetsFC">
        <ion-label>
          <div class="label-container">
            {{sujet.nom_suj}}
          </div>
        </ion-label>
      </ion-item>
  </ion-list>


  <ion-button (click)="posterIntervention()" color="secondary" expand="full" [disabled]="!site || (!sujetsFC || sujetsFC.length==0) || !type_inter">Créer la demande</ion-button>
</ion-content>
