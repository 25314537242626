import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { EVService } from '../../services/ev.service';
import { SelectwithfilterComponent } from '../../components/selectwithfilter/selectwithfilter.component';
import { UtilsService } from '../../services/utils.service';
@Component({
  selector: 'app-declenchementinterventions',
  templateUrl: './declenchementinterventions.page.html',
  styleUrls: ['./declenchementinterventions.page.scss'],
})
export class DeclenchementinterventionsPage implements OnInit {

  constructor(private utils:UtilsService,private popoverController : PopoverController ,private modalController : ModalController,private ev:EVService) { }
  sites;
  site;
  sujets;
  sujetsFC;
  type_inter;
  ngOnInit() {

    this.ev.v8_liste_site().subscribe(res => {
      this.sites = res;
  });
  }

  closeModal(){
    this.modalController.dismiss();
  }

  getSujets(id_sit:number) {
    this.ev.v8_sujets_a_equiper(id_sit).subscribe(res => {
      this.sujets = res;
    });
  }

  posterIntervention() {
    // console.log('posterIntervention',this.formIntervention.value);
    this.ev.v8_poster_intervention({sujetsFC:this.sujetsFC, type: this.type_inter}).subscribe(res => {

      if (res === 'success') {
        this.utils.presentToast('Intervention ajoutée avec succès',4000,'success');
        this.sujets = null;
        this.sujetsFC = null;
        this.site = null;
        this.type_inter = null;

      } else {
        this.utils.presentToast('Erreur lors de l\'ajout de l\'intervention',4000,'danger');
      }
    });
  }

  async openPopOver(title,items,key,id,text,multiple = false,target,subkey = null) {
    const popover = await this.popoverController.create({
      component: SelectwithfilterComponent,
      translucent: false,
      cssClass: 'monpopover',
      componentProps: {
        title: title,
        text: text,
        items: items,
        key : key,
        id : id,
        multiple: multiple,
        target : target,
        subkey: subkey
      }
    });

     await popover.present();
     const { data } = await popover.onDidDismiss();
     if (data !== null) {
      if (data.type== "site") {
        this.sujetsFC = null;
        this.site = data.selectedItem;
        this.getSujets(this.site.id_sit);
      }
      if (data.type == "sujetsFC") {
        this.sujetsFC = null;
        this.sujetsFC = data.selectedItems;
      }
      if (data.type == "type_inter") {
        this.type_inter = data.selectedItem;
        //On va refresh les sujets du site si il est déjà selectionné
        if (this.site) {
          this.sujetsFC = null;
          this.getSujets(this.site.id_sit);
        }
      }
     }
   }
}
