import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from '../../environments/environment';
import {IIntervention} from '../interfaces/IIntervention';
import {IInterventionHeader} from '../interfaces/IInterventionHeader';
import {UserRolesEnum} from '../enums/UserRolesEnum';
import {IMission} from '../interfaces/IMission';
import {Observable, BehaviorSubject} from 'rxjs';
import {IProjet} from '../interfaces/IProjet';
import {ISujet} from '../interfaces/ISujet';
import {ISujetPhoto} from '../interfaces/ISujetPhoto';
import {IModularFormData, IModularFormPayload} from '../interfaces/IFormStep';
import {IDiagPayload} from '../interfaces/Diag/IDiagPayload';
import {DiagTypeEnum} from '../enums/DiagTypeEnum';
import {IDiag} from '../interfaces/Diag/IDiag';
import {ITrame} from '../interfaces/ITrame';
import {IMinisense} from '../interfaces/IMinisense';
import {ProjetArchiveArray} from '../interfaces/ProjetDocuments';
import {UtilsService} from './utils.service';
import {Id} from '../interfaces/Id';
import {UrbaApiResponse} from '../enums/UrbaApiResponseStatus';

@Injectable({
    providedIn: 'root'
})
export class EVService {
    public listeInterventionLoading = false;
    private dataSourceInterventions = new BehaviorSubject<IIntervention[]>([]);
    currentDataSourceInterventions = this.dataSourceInterventions.asObservable();

    private dataSourceMissions = new BehaviorSubject<any>([]);
    currentDataSourceMissions = this.dataSourceMissions.asObservable();

    number_interventions = 0;
    number_missions = 0;

    constructor(
        private _http: HttpClient,
        private auth: AuthService,
        private utils: UtilsService,
    ) {
        this.auth.isAuthenticated$.subscribe(res => {
            if (res) {
                // this.v6_projets_interventions();
                // this.v6_projets_missions();
            }
        });
    }

    public getElectrovannes() {
        return this._http.get(environment.api_url + 'private/electrovannes');
    }

    public v6_projets(icaaForce = false) {
        return this._http.get(environment.api_url + 'private/projets/' + icaaForce);
    }

    public backInstall(step, installation) {
        installation.step = step;
        return this._http.put(environment.api_url + 'private/installation', JSON.stringify(installation));
    }

    public v6_checkUser() {
        return this._http.get(environment.api_url + 'private/checkUser');
    }

    public getInstallateurs() {
        return this._http.get(environment.api_url + 'private/installateurs');
    }

    public v7_reprogrammationEnCours() {
        return this._http.get(environment.api_url + 'private/projet/reprogrammationsEnCours');
    }


    public v6_secteur_bulletins_frise_external(token, id_groupe, id_sujet) {
        return this._http.get(environment.api_url + 'checkToken/' + token + '/frises/' + id_groupe + '/sujet/' + id_sujet);
    }

    public subscribeTrial(formTrial) {
        return this._http.post(environment.api_url + 'subscribetrial', JSON.stringify(formTrial));
    }

    public v8_poster_intervention(formIntervention) {
        return this._http.post(environment.api_url + 'private/intervention', JSON.stringify(formIntervention));
    }

    public post_process_installation(installForm: any) {
        return this._http.post(environment.api_url + 'private/installation', JSON.stringify(installForm));
    }

    public validerMission(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/validermission', JSON.stringify(objetMission));
    }

    public validerFinIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/validerFinIntervention', JSON.stringify(objetMission));
    }

    public invaliderFinIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/invaliderfinintervention', JSON.stringify(objetMission));
    }

    public proposerMission(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/proposermission', JSON.stringify(objetMission));
    }

    public accepterMission(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/acceptermission', JSON.stringify(objetMission));
    }

    public declinerMission(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/declinermission', JSON.stringify(objetMission));
    }

    public validerConformiteIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/validerconformiteintervention', JSON.stringify(objetMission));
    }

    public invaliderConformiteIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/invaliderconformiteintervention', JSON.stringify(objetMission));
    }

    public annulerIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/annulerintervention', JSON.stringify(objetMission));
    }


    public reproposerIntervention(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/reproposerintervention', JSON.stringify(objetMission));
    }

    validerFinMission(objetMission: Object) {
        return this._http.post(environment.api_url + 'private/validerfinmission', JSON.stringify(objetMission));
    }

    public getDetailIntervention(interv_id: Id): Observable<IIntervention> {
        return this._http.get<IIntervention>(environment.api_url + 'private/intervention/' + interv_id);
    }

    public getDetailMission(mission_id: Id): Observable<IMission> {
        return this._http.get<IMission>(environment.api_url + 'private/mission/' + mission_id);
    }

    public updatePositionInterventions(interventions: IIntervention[]) {
        return this._http.put(environment.api_url + 'private/interventions/position', interventions);
    }

    public deleteInterventionMission(id_intervention: Id) {
        return this._http.delete(environment.api_url + 'private/interventionmission/' + id_intervention);
    }

    public v6_projet_compteurs(id_projet: Id) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/compteur');
    }

    public v6_projets_actualite() {
        return this._http.get(environment.api_url + 'private/projets/actualite');
    }

    public v6_maintenance_projet(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/maintenance');
    }

    public v6_infos_compteur(id_compteur) {
        return this._http.get(environment.api_url + 'private/compteur/' + id_compteur);
    }

    public v8_utilisateurs_site(id_site) {
        return this._http.get(environment.api_url + 'private/site/utilisateurs/' + id_site);
    }

    public v6_liste_sujets_projet(id_projet) {
        return this._http.get(environment.api_url + 'private/liste_sujets_projets/' + id_projet);
    }

    public v6_check_last_trame_ev(id_device: string | number,id_sujet: string | number): Observable<ITrame> {
        return this._http.get<ITrame>(environment.api_url + 'private/equipement/lasttrame/' + id_device+'/'+id_sujet);
    }

    public v6_uploadImage(image: File, id_suj) {
        const formData = new FormData();
        formData.append('image', image);
        const req = new HttpRequest('POST', environment.api_url + 'private/sujet/' + id_suj + '/image', formData, {reportProgress: true});
        return this._http.request<any>(req);
    }

    public v6_deleteDoc(path_doc) {
        const req = new HttpRequest('DELETE', environment.api_url + 'private/document/' + path_doc);
        return this._http.request<any>(req);
    }

    public v6_uploadDoc(doc: File, id_site, implantation) {
        const formData = new FormData();
        formData.append('doc', doc);
        formData.append('implantation', implantation);
        const req = new HttpRequest('POST', environment.api_url + 'private/site/' + id_site + '/document', formData, {reportProgress: true});
        return this._http.request<any>(req);
    }


    public v6_projets_bulletins(position) {
        return this._http.get(environment.api_url + 'private/projets/bulletins/' + position);
    }

    public v6_sujet_update(sujet) {
        return this._http.post(environment.api_url + 'private/sujet', JSON.stringify(sujet));
    }

    public v6_archive_secteur(id_secteur) {
        return this._http.delete(environment.api_url + 'private/secteur/' + id_secteur);
    }

    public getInterventionsById(listIds: string[] | number[]): Observable<IIntervention[]> {
        const intervList = encodeURIComponent(JSON.stringify(listIds));
        return this._http.get<IIntervention[]>(environment.api_url + 'private/interventionsbyid/' + intervList);
    }

    public v6_ajout_secteur(obj_secteur) {
        return this._http.put(environment.api_url + 'private/secteur', JSON.stringify(obj_secteur));
    }

    public v6_rapport_passage(obj_passage: any) {
        return this._http.post(environment.api_url + 'private/rapport_passage', JSON.stringify(obj_passage));
    }

    public v6_delete_rapport_passage(id_rp) {
        return this._http.delete(environment.api_url + 'private/rapport_passage/' + id_rp);
    }

    public v6_update_rapport_passage(obj_passage: any) {
        return this._http.put(environment.api_url + 'private/rapport_passage', JSON.stringify(obj_passage));
    }

    public v6_modifier_secteur(obj_secteur) {
        return this._http.patch(environment.api_url + 'private/secteur', JSON.stringify(obj_secteur));
    }

    public v6_extract_bulletin(obj_date) {
        return this._http.get(environment.api_url + 'private/exporter_bulletins/' + obj_date.date_debut + '/' + obj_date.date_fin, {
            headers: {
                'Accept': 'text/html, application/xhtml+xml, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            },

            responseType: 'blob',
            observe: 'response'
        });
    }


    public v6_setPhotoRotation(photo: any) {
        const req = this._http.post<any>(environment.api_url + 'private/rotationphoto', JSON.stringify(photo));
        return req;
    }


    public v6_secteur_graphe(id_secteur) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/graphe');
    }

    public v6_sujet_create(sujet) {
        return this._http.post(environment.api_url + 'private/sujet/nouveau', JSON.stringify(sujet));
    }

    public archiveSujet(sujet) {
        return this._http.post(environment.api_url + 'private/sujet/archive', JSON.stringify(sujet));
    }

    public v6_setDureeSujet(sujet) {
        return this._http.post(environment.api_url + 'private/sujet/duree', JSON.stringify(sujet));
    }


    public v6_masquePhoto(suj_id_raw: number, myfile_raw: string) {
        return this._http.get(environment.api_url + 'private/photo/masquer/' + suj_id_raw + '/' + myfile_raw + '');
    }


    public v6_projet_liste_secteur_ev(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/secteur_ev_liste');
    }

    public detail_passage(id_passage) {
        return this._http.get(environment.api_url + 'private/detail_passage/' + id_passage);
    }

    public v6_projet_liste_secteur(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/secteur_liste');
    }


    public v6_projet_programmation(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/programmation');
    }

    public v6_secteur_programmation(id_secteur) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/programmation');
    }

    public v6_projet_entete(id_projet): Observable<IProjet> {
        return this._http.get<IProjet>(environment.api_url + 'private/projet/' + id_projet + '/entete');
    }

    public getPois(bounds, isIcaa) {
        return this._http.post<any>(environment.api_url + 'private/pois/' + isIcaa, bounds);
    }


    public v6_projet_programmation_historique(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/programmation_historique');
    }

    public v6_secteur_programmation_historique(id_secteur) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/programmation_historique');
    }

    public v6_delete_diagnostic(id_diag) {
        return this._http.delete(environment.api_url + 'private/diagnostic/' + id_diag);
    }


    public v6_delete_mission(id_mission) {
        return this._http.delete(environment.api_url + 'private/mission/' + id_mission);
    }

    public v6_delete_intervention(id_intervention) {
        return this._http.delete(environment.api_url + 'private/intervention/' + id_intervention);
    }


    public export_passage(objDate) {
        return this._http.get(environment.api_url + 'private/export_rapport_passage/' + objDate.date_debut + '/' + objDate.date_fin, {
            responseType: "blob",
            headers: {'Accept': 'application/vnd.ms-excel'}
        });

    }

    public v6_delete_remarque(id_remarque) {
        return this._http.delete(environment.api_url + 'private/remarque/' + id_remarque);
    }


    public v6_voies_secteurs(listeSecteurs) {
        return this._http.get(environment.api_url + 'private/voies/' + JSON.stringify(listeSecteurs));
    }

    public v6_reprogrammer(listeVoies) {
        return this._http.put(environment.api_url + 'private/reprogrammer/', JSON.stringify(listeVoies));
    }

    public dt_reprogrammer(id_sujet, id_device, programme, voie) {
        const obj = {
            id_sujet: id_sujet,
            id_device: id_device,
            programme: programme,
            voie: voie
        };
        return this._http.put(environment.api_url + 'private/dt_reprogrammer/', JSON.stringify(obj));
    }


    public v6_setMinisense(qrcode: any) {
        const req = this._http.put<any>(environment.api_url + 'private/minisense/', qrcode);
        return req;
    }

    public v6_enrollMinisense(qrcode: any): Observable<IMinisense> {
        const req = this._http.post<any>(environment.api_url + 'private/minisense/', qrcode);
        return req;
    }

    public v6_checkMinisense(obj: any) {
        const req = this._http.post<any>(environment.api_url + 'private/minisensecheck/', JSON.stringify(obj));
        return req;
    }

    public minisenseUp(obj: any) {
        const req = this._http.post<any>(environment.api_url + 'private/minisenseUp/', JSON.stringify(obj));
        return req;
    }


    public listMinisense() {
        const req = this._http.get<any>(environment.api_url + 'private/ms');
        return req;
    }


    public v6_urbadata() {
        return this._http.get<any>(environment.api_url + 'private/urbadata');
    }

    public v6_device_info(id_device) {
        return this._http.get<any>(environment.api_url + 'private/device/' + id_device);
    }

    public v6_updateSujetsDuGroupe(liste) {
        const req = this._http.post<any>(environment.api_url + 'private/secteur/composition', liste);
        return req;
    }


    public getRemarquesSujet(idSujet: number) {
        return this._http
            .get(environment.api_url + 'private/sujet/' + idSujet + '/remarques');
    }

    public getDiagnosticsSujet(idSujet: number): Observable<IDiag[]> {
        return this._http
            .get<IDiag[]>(environment.api_url + 'private/sujet/' + idSujet + '/diagnostics');
    }

    public getDiag(idDiag: number | string): Observable<IDiag> {
        return this._http
            .get<IDiag>(environment.api_url + 'private/diag/' + idDiag);
    }


    public v6_projets_photos(position) {
        return this._http.get(environment.api_url + 'private/projets/photos/' + position);
    }

    public v6_projet_single_photos(projetId: string | number, position: number = 0, filterParams: string): Observable<ISujetPhoto[]> {
        return this._http.get<ISujetPhoto[]>(environment.api_url + 'private/projet/' + projetId + '/photos/' + position + '/' + filterParams);
    }


    public getOngletsIFrame(token) {
        return this._http.get(environment.api_url + 'ongletsiframe/' + token);
    }


    public resetInterventions() {
        return new Promise((resolve, reject) => {
            this.dataSourceInterventions.next([]);
            this.number_interventions = 0;
            resolve(true);
        });
    }

    public resetMissions() {
        return new Promise((resolve, reject) => {
            this.dataSourceMissions.next([]);
            this.number_missions = 0;
            resolve(true);
        });
    }

    public v6_projets_missions(type_demandeur: UserRolesEnum | string, mustReset = true) {
        if (mustReset == true) {
            this.number_missions = 0;
        }
        return this._http.get<IMission>(environment.api_url + 'private/projets/missions/' + type_demandeur + '/' + this.number_missions).subscribe(
            res => {
                if (!mustReset) {
                    this.dataSourceMissions.next(this.dataSourceMissions.getValue().concat(res));
                } else {
                    this.dataSourceMissions.next(res);
                }
            }
        );
    }

    public v6_projets_missions_observable(type_demandeur: UserRolesEnum | string) {
        return this._http.get<IMission>(environment.api_url + 'private/projets/missions/' + type_demandeur + '/' + 100);
    }

    public v6_maintenance_map() {
        return this._http.get<any>(environment.api_url + 'private/maintenance_map');
    }

    public v6_projets_interventions(
        type_demandeur: UserRolesEnum,
        searchQuery: string = '',
        mustReset: boolean,
        queryParams: URLSearchParams,
    ) {
        if (mustReset == true) {
            this.number_interventions = 0;
        }
        this.listeInterventionLoading = true;
        return this._http
            .get<IIntervention[]>(`${environment.api_url}private/projets/interventions/${this.number_interventions}/${type_demandeur}/${searchQuery}?${queryParams.toString()}`)
            .subscribe(
                res => {
                    // TODO remove
                    console.log(res)
                    console.log(res.length)
                    this.listeInterventionLoading = false;
                    if (mustReset == false) {
                        this.dataSourceInterventions.next(this.dataSourceInterventions.getValue().concat(res));
                    } else {
                        this.dataSourceInterventions.next(res);
                    }
                }
            );
    }

    public v6_projets_intervention_observable(type_demandeur: UserRolesEnum) {
        return this._http.get<IIntervention[]>(environment.api_url + 'private/projets/interventions/' + this.number_interventions + '/' + type_demandeur);
    }

    public v6_interventions_by_projet(idProjet: string) {
        return this._http.get<IIntervention[]>(environment.api_url + 'private/projet/interventions/' + idProjet);
    }

    public v6_projets_interventions_headers(type_demandeur: UserRolesEnum): Observable<IInterventionHeader[]> {
        return this._http.get<IInterventionHeader[]>(environment.api_url + 'private/projets/interventions_headers/' + type_demandeur);
    }

    public v6_projets_missions_headers(type_demandeur) {
        return this._http.get(environment.api_url + 'private/projets/missions_headers/' + type_demandeur);
    }

    public v6_setDiagPiedArbre(diagnosis) {
        return this._http.post(environment.api_url + 'private/sujet/diagnostic', JSON.stringify(diagnosis));
    }

    public v6_sujet_remarque(remarque) {
        return this._http.post(environment.api_url + 'private/sujet/remarque', JSON.stringify(remarque));
    }

    public v6_sujet_header(id_sujet) {
        return this._http.get(environment.api_url + 'private/sujet/' + id_sujet + '/header');
    }

    public v6_sujet_vegetal(id_sujet) {
        return this._http.get(environment.api_url + 'private/sujet/' + id_sujet + '/vegetal');
    }

    public v6_sujet_courbes(id_sujet) {
        return this._http.get(environment.api_url + 'private/sujet/' + id_sujet + '/courbes');
    }

    /**
     * Returns a Sujet with its Photos
     */
    public v6_sujet_photos(id_sujet: number | string): Observable<ISujet> {
        return this._http.get<ISujet>(environment.api_url + 'private/sujet/' + id_sujet + '/photos');
    }

    public v6_sujet_map(id_sujet) {
        return this._http.get(environment.api_url + 'private/sujet/' + id_sujet + '/map');
    }

    public v6_projet_bulletins(id_projet, position) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/bulletins/' + position);
    }

    public v6_secteurs_electrovannes(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/secteurs/electrovannes');
    }

    public v6_secteur_bulletins(id_secteur, position) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/bulletins/' + position);
    }

    public v6_secteur_bulletins_frise(id_secteur: Id) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/bulletins_frise');
    }

    public v6_projet_bulletins_frise(id_projet: Id) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/bulletins_frise');
    }

    public v6_projet_documents(id_projet: Id): Observable<ProjetArchiveArray> {
        return this._http.get<ProjetArchiveArray>(environment.api_url + 'private/projet/docs/' + id_projet);
    }

    public v6_renommer_secteur(data) {
        return this._http.put(environment.api_url + 'private/secteur/renommer', JSON.stringify(data));
    }

    public v6_renommer_programme(data) {
        return this._http.put(environment.api_url + 'private/programme/renommer', JSON.stringify(data));
    }

    public v6_renommer_compteur(data) {
        return this._http.put(environment.api_url + 'private/compteur/renommer', JSON.stringify(data));
    }

    public v6_secteurs_equipements(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/secteurs/equipements');
    }

    public v6_projet_historique_ev(id_projet, position) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/historique_ev/' + position);
    }

    public v6_projet_contacts(id_projet) {
        return this._http.get(environment.api_url + 'private/projet/' + id_projet + '/contacts');
    }

    public v6_projet_historique_ev_secteur(id_secteur) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/historique_ev');
    }

    public registerMinisense(obj) {
        return this._http.put(environment.api_url + 'private/minisense', JSON.stringify(obj));
    }

    public v6_secteur_equipements(id_secteur) {
        return this._http.get(environment.api_url + 'private/secteur/' + id_secteur + '/equipements');
    }

    public v6_detail_bulletin(id_bulletin) {
        return this._http.get(environment.api_url + 'private/bulletin/' + id_bulletin);
    }

    public v7_detail_bulletin(id_bulletin) {
        return this._http.get(environment.api_url + 'private/bulletinv7/' + id_bulletin);
    }

    public v6_programmations(id_projet) {
        return this._http.get(environment.api_url + 'private/programmations/' + id_projet);
    }

    public v7_projet_graphe(id_projet, page_number) {
        return this._http.get(environment.api_url + 'private/projet/graphe/' + id_projet + '/' + page_number);
    }


    public v6_programmation_delete(id_programmation) {
        return this._http.delete(environment.api_url + 'private/programmation/' + id_programmation);
    }

    public v6_secteur_remarque(id_secteur, remarque) {
        return this._http.put(environment.api_url + 'private/secteur/' + id_secteur + '/remarque/', JSON.stringify(remarque));
    }

    public getInfosNotification(id_projet, sub) {
        let objNotif = {
            'id_projet': id_projet,
            'sub': sub
        }
        return this._http.post(environment.api_url + 'private/infosnotifications', JSON.stringify(objNotif));

    }


    public linkCompteur(compteur) {

        return this._http.put(environment.api_url + 'private/link_compteur/', JSON.stringify(compteur));
    }

    public unlink_compteur(compteur) {

        return this._http.put(environment.api_url + 'private/unlink_compteur/', JSON.stringify(compteur));
    }


    public v6_compteur_save(compteur, id_projet) {
        compteur = {
            'compteur': compteur.nom
        }
        return this._http.put(environment.api_url + 'private/compteur/' + id_projet, JSON.stringify(compteur));
    }

    public v6_programmation_save(programmation, id_projet) {
        programmation = {
            'nom': programmation.nom,
            'contenu': {
                'duree': programmation.duree,
                'depart': programmation.depart,
                'frequence': programmation.frequence,
                'planning': programmation.planning
            }
        }
        return this._http.put(environment.api_url + 'private/programmation/' + id_projet, JSON.stringify(programmation));
    }


    public v6_programmation_secteur_save(id_secteur, id_programmation) {
        let programmation = {
            'id_secteur': id_secteur,
            'id_programmation': id_programmation

        }
        return this._http.put(environment.api_url + 'private/programmation_secteur', JSON.stringify(programmation));
    }


    public v6_programmation_detail(id_programme: Id) {
        return this._http.get(environment.api_url + 'private/programmation/' + id_programme);
    }


    public v6_projet(idProjet: Id): Observable<any> { // { droits, sujets[] }
        return this._http
            .get<any>(environment.api_url + 'private/projet/' + idProjet + '');
    }

    public v8_sujets_a_equiper(id_sit: number) {
        return this._http
            .get(environment.api_url + 'private/sujets_a_equiper/' + id_sit);
    }

    public v8_liste_site() {
        return this._http
            .get(environment.api_url + 'private/liste_site');
    }

    public v6_secteur(id_secteur: number) {
        return this._http
            .get(environment.api_url + 'private/secteur/' + id_secteur + '');
    }

    public v8_secteur(id_secteur: number) {
        return this._http
            .get(environment.api_url + 'private/secteur_8/' + id_secteur + '');
    }

    public v6_update_aggregation(docs){
        return this._http
            .post(environment.api_url + 'private/aggregation',docs, { responseType: 'blob' });
    }

    public exportExcel(type: string) {
        return this._http.get(environment.api_url + 'private/export_excel/' + type, {
            headers: {
                'Accept': 'text/html, application/xhtml+xml, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob',
            observe: 'response'
        });
      }


    public v6_sujet(id_sujet: number | string): Observable<ISujet> {
        return this._http
            .get<ISujet>(environment.api_url + 'private/sujet/' + id_sujet + '');
    }

    public v6_sujet_edition(id_sujet: number) {
        return this._http
            .get(environment.api_url + 'private/sujet/' + id_sujet + '/edition');
    }

    async v6_projet_overlay(id_projet: number): Promise<any> {
        return this._http
            .get(environment.api_url + 'private/projet/' + id_projet + '/carte')
            .toPromise();
    }


    checkChronology(id_projet: number) {
        return this._http
            .get(environment.api_url + 'private/projet/' + id_projet + '/chronologie');
    }

    public v6_projet_geojson(id_projet: number) {
        return this._http
            .get(environment.api_url + 'private/projet/' + id_projet + '/geojson');
    }

    public loadGraphData(period: string, id_sujet: number) {
        return this._http.get(environment.api_url + 'private/graphe/' + period + '/' + id_sujet);
    }

    public get_diag_by_project_and_type<T extends IDiag>(projectId: number | string, diagType: DiagTypeEnum): Observable<T[]> {
        return this._http.get<T[]>(`${environment.api_url}private/diag/${diagType}/site/${projectId}`);
    }

    public post_diag_data<T extends IDiag>(
        diag: T,
        files: IModularFormData = null
    ): Observable<UrbaApiResponse> {
        const payload: IDiagPayload<T> = {diag, files};
        console.log(this._http);
        return this._http.post<UrbaApiResponse>(environment.api_url + 'private/diag/', payload);
    }

    public put_diag_data<T extends IDiag>(
        diag: T,
        files: IModularFormData = null
    ): Observable<UrbaApiResponse> {
        const payload: IDiagPayload<T> = {diag, files};
        return this._http.put<UrbaApiResponse>(environment.api_url + 'private/diag/' + diag.diag_id, payload);
    }

   public post_sujet_maintenance(sujetId: Id, formPayload: IModularFormPayload): Observable<any> {
        const payload = {
            formData: formPayload.formData,
            files: formPayload.files,
        };

        return this._http.post<any>(environment.api_url + 'private/maintenance/' + sujetId, payload);
    }

    public get_check_intervention_by_sujet(sujetId: string | number): Observable<any> {
        return this._http.get<any>(environment.api_url + 'private/check_maintenance/' + sujetId);
    }

    public get_transfer_mission_to_new_installateur(missionId: string | number, installateurId: string | number): Observable<any> {
        return this._http.get<any>(`${environment.api_url}private/transferermission/${missionId}/${installateurId}`);
    }

    public post_maintenance_snatch(interventionId: string | number) {
        return this._http.post<any>(`${environment.api_url}private/maintenance/snatch/${interventionId}`, {});
    }


    public get_blob(url: string) {
        return this._http.get(url, {responseType: 'blob'}).toPromise();
    }

}
