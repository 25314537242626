

import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Router } from '@angular/router';
import {Observable, of, throwError} from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable()
export class UrbasenseHttpRequestInterceptor implements HttpInterceptor {
    constructor(private _router: Router) {}
intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status == 401) {
                    this._router.navigateByUrl('/erreur');
                }
                console.log(err);

                return throwError(err);
            }));

      }

}
